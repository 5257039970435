import React, { useContext } from 'react'
import { Link } from 'gatsby'
import headerStyles from './header.module.scss'
import Logo from '../images/logo.png'
import { Context } from './store.js'
import { StaticImage } from "gatsby-plugin-image"

const Header = (props) => {
    const [state, setState] = useContext(Context)

    let headerContent

    if (props.large) {
        headerContent =
            <div className={"container " + headerStyles.header__container}>
                <div>
                    {props.children}
                </div>
            </div>
    }

    const openNavigation = (e) => {
        e.preventDefault()
        setState({ navigation: true })
    }

    function handleClick(e) {
        e.preventDefault()
        window.$crisp.push(["do", "chat:open"])
    }

    //work out correct class based on props
    const size = props.large ? `${headerStyles.header} ${headerStyles.header__large}` : `${headerStyles.header}`

    const dark = props.dark ? `${headerStyles.header__dark}` : ``

    const wave = props.disable ? `${headerStyles.header__wave} ${headerStyles.header__wave__disable}` : `${headerStyles.header__wave}`

    return (

        <header className={`${size} ${dark}`}>

            <div className={headerStyles.header__top}>

                <div className={"container " + headerStyles.header__top__number}>
                    <p>Celebrating 12 years</p>
                    <a href="https://account.host3.co.uk">My Account<i className="icon icon-right-small"></i></a>
                </div>

                <div className={"container " + headerStyles.header__top__container}>

                    <div className={headerStyles.header__top__nav}>

                        <Link to="/">
                            <StaticImage
                                loading="eager"
                                src="../images/logo.png"
                                alt="Host3 Logo"
                                width={90}
                                objectFit="contain"
                                placeholder="none"
                            />
                        </Link>

                        <nav>
                            <ul>
                                <li><Link to="/solutions/" activeClassName={headerStyles.active}>Solutions</Link>
                                    <ul>
                                        <li><Link to="/cloud-hosting/" activeClassName={headerStyles.active}><i className="icon icon-cloud"></i><p><span>Cloud Hosting</span>Ready-built affordable cloud hosting with cPanel and high availability</p></Link></li>
                                        <li><Link to="/wordpress-hosting/" activeClassName={headerStyles.active}><i className="icon icon-wordpress"></i><p><span>WordPress Hosting</span>High-performance WordPress Hosting built for the cloud</p></Link></li>
                                        {/*<li><Link to="/cloud-hosting/" activeClassName={headerStyles.active}><i className="icon icon-cloud"></i><p><span>Critical Cloud</span>Custom built high redundancy, availability and performance</p></Link></li>*/}
                                    </ul>
                                </li>
                                {/*<li><Link to="/solutions/" activeClassName={headerStyles.active}>Products</Link></li>*/}
                                <li><Link to="/why/" activeClassName={headerStyles.active}>Why Host3?</Link></li>
                                <li><Link to="/support/" activeClassName={headerStyles.active}>Support</Link></li>
                            </ul>
                        </nav>

                    </div>

                    <div className={headerStyles.header__buttons}>

                        <a href="" className="button button--small button__transparent" onClick={handleClick}><i className="icon icon-chat"></i> Chat</a>

                        <Link to="/solutions/" className="button button--small button__orange hidden-mobile">Get Started</Link>

                        <button className="button button--small button__blue hidden-desktop button__navigation" aria-label="Open Navigation" onClick={openNavigation}><i className="icon icon-menu"></i></button>

                    </div>

                </div>

            </div>

            {headerContent}

            <div className={`${wave}`}></div>

        </header>
    )
}

export default Header