import React, { useContext } from 'react'
import { Link } from 'gatsby'
import navigationStyles from './navigation.module.scss'
import { Context } from './store.js'

const Navigation = (props) => {
    const [state, setState] = useContext(Context)

    const handleClick = () => {
        setState({navigation: false})
    }
    
    return (
        <div className={state.navigation ? `${navigationStyles.navigation} ${navigationStyles.navigation__active}` : `${navigationStyles.navigation}`}>
            <div className={navigationStyles.navigation__container}>

                <button className="button button--small button__blue button__navigation" aria-label="Close Navigation" onClick={handleClick}>X</button>

                <div className={navigationStyles.navigation__content}>

                    <h4><Link to="/solutions/" activeClassName={navigationStyles.active}>Solutions<i className="icon icon-right-small"></i></Link></h4>

                    <ul>
                        <li><Link to="/cloud-hosting/" activeClassName={navigationStyles.active}><i className="icon icon-cloud"></i>Cloud Hosting</Link></li>
                        <li><Link to="/wordpress-hosting/" activeClassName={navigationStyles.active}><i className="icon icon-wordpress"></i>WordPress Hosting</Link></li>
                    </ul>

                    <div className={navigationStyles.navigation__divider}></div>

                    <h4><Link to="/support/" activeClassName={navigationStyles.active}>Support<i className="icon icon-right-small"></i></Link></h4>

                    <ul>
                        <li><a href="https://account.host3.co.uk/supporttickets.php"><i className="icon icon-ticket"></i>Ticket</a></li>
                        <li><a href="https://account.host3.co.uk/clientarea.php"><i className="icon icon-chat"></i>Live Chat</a></li>
                        <li><a href="https://account.host3.co.uk/clientarea.php"><i className="icon icon-phone"></i>Phone</a></li>
                        <li><Link to="/contact/" activeClassName={navigationStyles.active}><i className="icon icon-question-circle-o"></i>Pre-Sales</Link></li>
                    </ul>

                    <div className={navigationStyles.navigation__divider}></div>

                    <h4>Discover</h4>

                    <ul>
                        <li><Link to="/why/" activeClassName={navigationStyles.active}><i className="icon icon-right-small"></i>Why Host3?</Link></li>
                    </ul>

                </div>

                <div className={navigationStyles.navigation__footer}>
                    <Link to="/contact/" className="button button--small button__transparent">Contact Us</Link>

                    <a href="https://account.host3.co.uk" className="button button--small button__blue">My Account</a>
                </div>
            </div>
        </div>
    )
}

export default Navigation